import * as React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import './styles.scss'

const FeatureRadio = ({
    label,
    groupName,
    name,
    value,
    icon,
    isSubmitting,
    isSubmitted,
    onChange
}) => {
    return (
        <div
            className={`c-feature-radio c-feature-radio--${icon}`}
            onChange={onChange}
        >
            <Field
                type="radio"
                name={groupName}
                id={name}
                value={value}
                disabled={isSubmitting || isSubmitted}
            />
            <label htmlFor={name}>
                <span>{label}</span>
            </label>
        </div>
    )
}

FeatureRadio.propTypes = {
    /**
     * Radio label
     */
    label: PropTypes.string.isRequired,
    /**
     * Radio button group name
     */
    groupName: PropTypes.string,
    /**
     * Radio name
     */
    name: PropTypes.string.isRequired,
    /**
     * Radio value
     */
    value: PropTypes.string.isRequired,
    /**
     * Radio icon
     */
    icon: PropTypes.string.isRequired,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * onChange function
     */
    onChange: PropTypes.func
}

FeatureRadio.defaultProps = {
    label: 'My Family',
    groupName: 'protect',
    name: 'family',
    value: 'family',
    icon: 'family',
    isSubmitting: false,
    isSubmitted: false,
    onChange: undefined
}

export default FeatureRadio
